import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import SkillList from "./SkillList";
import Type from "./Type";
import CallToAction from "./CallToAction";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There {" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>. 
                I'm <strong className="main-name"> Getachew! </strong> 
              </h1>

              <div className="heading-name">
                <p> As a <b className="proposition">Business Consultant</b>, I'm a data-driven expert specializing in building and scaling data & AI-powered solutions through advanced analytics, large language models, and innovative business models.</p>
              </div>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <SkillList />
      <CallToAction /> 
    </section>
  );
}

export default Home;
