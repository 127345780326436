import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skill1 from "./Skills/Skill1";
import Skill2 from "./Skills/Skill2";
import Skill3 from "./Skills/Skill3";
import Skill4 from "./Skills/Skill4";
import Tilt from "react-parallax-tilt";


function SkillList() {
  return (
    <Container fluid className="home-about-section" id="about">
        <Row>    
          <Col lg={3} md={6} sm={12}  className="home-about-description">
          <Tilt>
            <ul className="skill-list-items">
             <Skill1 />
            </ul>
          </Tilt>
          </Col>

          <Col lg={3} md={6} sm={12} className="home-about-description">
          <Tilt>
            <ul className="skill-list-items">
             <Skill2 />
            </ul>
          </Tilt>
          </Col>

          <Col lg={3} md={6} sm={12}  className="home-about-description">
          <Tilt>
            <ul className="skill-list-items">
             <Skill3 />
            </ul>
          </Tilt>

          </Col>

          <Col lg={3} md={6} sm={12}  className="home-about-description">
          <Tilt>
            <ul className="skill-list-items">
             <Skill4 />
            </ul>
          </Tilt>
          </Col>
        </Row>

    </Container>
  );
}
export default SkillList;
