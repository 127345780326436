
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineMail} from "react-icons/ai";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import Divider from "./Divider";

function CallToAction() {
  return (
    <Container fluid className="home-about-section" id="about">
      
      <Divider />
      <Row >
        <Col md={12}>
          <h6 className="call-to-action">
            I'm open to collaborating with innovative teams, investors, and organizations to transform data into tangible business value.
          </h6>
        </Col>
      </Row>
      <Divider/>
      <br /> <br />

      <Row>
        <Col md={12} className="home-about-social">
          <h5>I'm based in Addis Ababa, Ethiopia.</h5>
          <h5>Find me on</h5>

          <ul className="home-about-social-links">
            <li className="social-icons">
              <a 
                  href="https://www.linkedin.com/in/gabegaz/"
                  target="_blank"
                  rel="noreferrer"
                >
              <FaLinkedinIn/>
              </a>
            </li>
            
            <li className="social-icons">
              <a href="mailto:getabegaz@gmail.com">
              <AiOutlineMail/>
              </a>
            </li>
            
            <li className="social-icons">
              <a href={`https://wa.me/251911480370`} target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
              </a>
            </li>


          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default CallToAction;
